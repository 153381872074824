import ApiService from './api.service'
const InspectionResultService =  {
    GetList(data){
        return ApiService.post(`/InspectionResult/GetList`, data)
    },
    Get(id){
      return ApiService.get(`/InspectionResult/Get/${id}`)
    },
    DownloadActFile(id){
      return ApiService.print(`/InspectionResult/DownloadActFile/${id}`)
    },
    DownloadMeasuresOfInfluenceFile(id){
      return ApiService.print(`/InspectionResult/DownloadMeasuresOfInfluenceFile/${id}`)
    },
    DownloadMeasuresResultFile(id){
      return ApiService.print(`/InspectionResult/DownloadMeasuresResultFile/${id}`)
    },
    DownloadCancelledMeasuresFile(id){
      return ApiService.print(`/InspectionResult/DownloadCancelledMeasuresFile/${id}`)
    },
    GetSecurityInfo(){
        return ApiService.print(`/InspectionResult/GetSecurityInfo`)
    }
}
export default InspectionResultService